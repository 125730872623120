.App {
  flex: 1;
  max-height: 100%;
  min-height: 100vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #262626;
  color: #ffffff;
  font-family: 'Poppins Light';
  padding-bottom: 100px;
  overflow-x: auto;
}
.Nav {
  width: 95%;
  margin: 3%;
  padding-left: 3%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}
.Nav > a > img {
  width: 50px;
}
.BodyContainer {
  width: 100%;
  padding: 0px 100px 0 200px;
  box-sizing: border-box;
}
.BodyHeader {
  font-family: 'Poppins Light';
  font-size: 51px;
  line-height: 62px;
  margin-top: 40px;
}
.BodyDescription {
  padding: 20px 40px 40px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}
.DescriptionLink {
  color: #fbc51b;
  text-decoration: none;
}
.HeroNavLinks {
  display: flex;
  line-height: 28px;
}
.HeroNavLinks > a {
  color: #ffffff;
  margin-right: 35px;
}
.ActiveLink {
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 2px solid #fbc51b;
}
.InactiveLink {
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 2px solid #4b4b4b;
  opacity: 80%;
}
.HeroSection {
  margin-top: 10px;
  height: fit-content;
  overflow: visible;
}

.Form {
  width: 50%;
  height: auto;
  padding: 1% 0;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.Form > label {
  width: 90%;
  margin: 1% 0;
}
.Form > select {
  width: 90%;
  margin: 1% 0;
}
.Form > button {
  width: 30%;
  margin: 1% 0;
}

.Form > hr {
  width: 90%;
}

.newFeed {
  width: 40px;
}

.resultsContainer {
  width: 50%;
  height: auto;
  padding: 1% 0;
  margin-top: 2%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.results {
  /* border: 1px solid red; */
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 786px) {
.BodyContainer {
    padding: 0px 10px 0 10px;
  }
}