.Button {
  font-family: 'Poppins Light' !important;
  font-size: 16px;
  padding: 4px 25px 8px 25px;
  background-color: transparent;
  border: 2px solid #fbc51b;
  color: rgb(251, 197, 27);
  text-align: center;
}
.ButtonDisabled {
  font-family: 'Poppins Light' !important;
  font-size: 16px;
  padding: 4px 25px 8px 25px;
  background-color: transparent;
  border: 2px solid #4b4b4b;
  color: #4b4b4b;
}
.Button:hover {
  cursor: pointer;
}
.ButtonDisabled:hover {
  cursor: not-allowed;
}

#ButtonSpecial {
  margin-right: 10px;
}
