.CustomFeedContainer {
  margin-top: 50px;
  display: flex;
}
.CustomFeedHeroContainer {
  width: 72%;
  overflow-wrap: break-word;
}
.CustomHero {
  max-width: 80%;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  border-left: 2px solid #ffffff;
  padding: 8px;
  padding-left: 20px;
}
.OpenObject {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.CustomInputContainer {
  display: flex;
  align-items: baseline;
  padding-left: 16px;
}
.CustomInputContainer > p:first-child {
  font-weight: 700;
}
.CustomInput {
  font-family: 'Poppins Light';
  font-size: 16px;
  font-weight: 300;
  width: 170px;
  height: 22px;
  padding-left: 5px;
  padding-top: 2px;
  border: none;
  background-color: rgba(251, 197, 27, 0.2);
  border-bottom: 2px solid #fbc51b;
  color: #ffffff;
  caret-color: #ffffff;
}
.CustomInput:focus {
  outline-color: #fbc51b;
  outline-width: 1px;
}
.CustomInput::placeholder {
  color: #ffffff;
  opacity: 0.8;
}
.ArgumentContainer {
  display: flex;
  max-width: 100%;
  height: fit-content;
  padding-left: 16px;
  margin-top: 8px;
}
.ArgumentContainer > p {
  font-weight: 700;
}
.ArgumentContainer > span {
  width: 100%;
  height: fit-content;
  overflow-wrap: break-word;
}
.DynamicFormContainer {
  display: flex;
}
.DynamicFormDropdown {
  font-family: 'Poppins Light';
  font-size: 16px;
  font-weight: 300;
  width: fit-content;
  height: 27px;
  padding-left: 5px;
  padding-top: 2px;
  border: none;
  background-color: rgba(251, 197, 27, 0.2);
  border-bottom: 2px solid #fbc51b;
  color: #ffffff;
  margin: 0px 12px 20px 0px;
}
.DynamicFormDropdown:focus {
  outline-color: #fbc51b;
  outline-width: 1px;
}
.DynamicFormDropdown:hover {
  cursor: pointer;
}
#BoolDropdown {
  width: 173px;
}
.DynamicFormInput {
  font-family: 'Poppins Light';
  font-size: 16px;
  font-weight: 300;
  width: fit-content;
  height: 22px;
  padding-left: 5px;
  padding-top: 2px;
  border: none;
  background-color: rgba(251, 197, 27, 0.2);
  border-bottom: 2px solid #fbc51b;
  color: #ffffff;
  caret-color: #ffffff;
  margin-right: 10px;
}
.DynamicFormInput:focus {
  outline-color: #fbc51b;
  outline-width: 1px;
}
.DeleteIconContainer {
  width: 25px;
  height: 25px;
  display: flex;
  border: 1px solid #fbc51b;
}
.DeleteIconContainer:hover {
  cursor: pointer;
}
.ErrorMessage {
  color: red;
  margin-bottom: 20px;
}
.ErrorMessage > span {
  font-weight: 700;
}
.CustomFeedResults {
  max-width: 90%;
  box-sizing: border-box;
  height: fit-content;
  border: 2px solid #ffffff;
  padding: 12px 12px 0px 20px;
  margin-top: 30px;
  overflow-wrap: break-word;
}
